<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n8">
                <v-col>
                    <tile-search-date
                        init-type="WEEK"
                        @changed="searchDateChanged"
                        @inited="searchDateInited"
                        ref="date_range_select"
                        :from-day="searchParam.search_start"
                        :to-day="searchParam.search_end"
                    ></tile-search-date>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">
                            mdi-magnify
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>
            <v-sheet
                class="v-sheet--offset mx-auto pa-5 mb-5"
                elevation="2"
                v-if="statList && statList.length > 1"
            >
                <TrendChart
                    :datasets="[
                        {
                        data: graphValues1,
                        smooth: true,
                        fill: true,
                        showPoints: true,
                        className: 'curve1'
                        },
                        {
                        data: graphValues2,
                        smooth: true,
                        showPoints: true,
                        className: 'curve2'
                        },
                        {
                        data: graphValues3,
                        smooth: true,
                        showPoints: true,
                        className: 'curve3'
                        },
                        {
                        data: graphValues4,
                        smooth: true,
                        showPoints: true,
                        className: 'curve4'
                        }
                    ]"
                    :grid="{
                        verticalLines: true,
                        horizontalLines: true
                    }"
                    :labels="{
                        xLabels: graphLabels,
                        yLabels: 7,
                        yLabelsTextFormatter: val => Math.round(val)
                    }"
                    :min="0"
                    height="450"
                    :interactive="true"
                    @mouse-move="onMouseMoveOfGraph"
                    class="daily-chart"
                >
                </TrendChart>
            </v-sheet>
            <v-card flat class="mt-4">
                <v-simple-table class="border-table">
                    <template v-slot:default>
                        <thead>
                        <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                            <th class="text-center text-h6">
                                일자
                            </th>
                            <th class="text-center text-h6">
                                총 수신전화
                            </th>
                            <th class="text-center text-h6">
                                콜리연결
                            </th>
                            <th class="text-center text-h6">
                                포기전화
                            </th>
                            <th class="text-center text-h6">
                                콜백요청
                            </th>
                        </tr>
                        </thead>
                        <tbody style="color:#555555;">
                        <template v-for="(statItem, statIdx) in statList">
                            <tr style="height:68px;" :key="statIdx">
                                <td class="text-center" style="font-size:20px;">
                                    {{ statItem.regdate | dateMin }}
                                </td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.call_count - statItem.visit_history_count }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.trans_succ_count }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.drop_call_count - statItem.visit_history_count }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.callback_req_count }}</td>
                            </tr>
                        </template>
                        <tr style="height:69px;" v-if="!statList || statList.length == 0">
                            <td class="text-center" style="font-size:20px;" colspan="5">조회된 결과가 없습니다</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            <div id="pop" role="tooltip" ref="tooltip" class="tooltip">
                <div class="tooltip-container" v-if="focusedIdx >= 0">
                    <strong>총 수신전화 : {{ focusedData[0] }}</strong><br/>
                    <strong>콜리연결 : {{ focusedData[1] }}</strong><br/>
                    <strong>포기전화 : {{ focusedData[2] }}</strong><br/>
                    <strong>콜백요청 : {{ focusedData[3] }}</strong>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapState} from 'vuex'
import {createPopper} from '@popperjs/core';

export default {
    name: 'stats_user_list',
    components: {
        'TileSearchDate': () => import('@/components/stats/tile_search_date.vue'),
    },
    data: () => ({
        searchParam: {
            search_start: '',
            search_end: '',
        },
        focusedIdx: -1,
        focusedData: null,
        popper: null,
        initFlags: {'search_date': false},
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'stats_svc_call')

        let lastMenuId = this.$store.getters['lastMenuId']
        if (lastMenuId == 'stats_svc_call' && this.curSearchParam) {
            Object.assign(this.search, this.curSearchParam)
        }

        this.doSearch(1)
    },
    computed: {
        ...mapState('stats_svc_call', {
            curSearchParam: state => state.searchParam,
            totalCount: state => state.totalCount,
            statList: state => state.statList,
        }),
        isLoaded() {
            if (this.statList) {
                return true
            }
            return false
        },
        graphLabels() {
            let ret
            if (this.statList && this.statList.length > 0) {
                let l = this.statList.length
                ret = new Array(l)
                this.statList.forEach((item, itemIdx) => {
                    let d = item.regdate
                    if (d && d.length == 8) {
                        d = d.substring(4, 6) + '/' + d.substring(6)
                    }
                    ret[l - itemIdx - 1] = d
                })
            } else {
                return []
            }

            return ret
        },
        graphValues1() {
            let ret
            if (this.statList && this.statList.length > 0) {
                let l = this.statList.length
                ret = new Array(l)
                this.statList.forEach((item, itemIdx) => {
                    let d = item.call_count - item.visit_history_count
                    ret[l - itemIdx - 1] = d
                })

            } else {
                return []
            }

            return ret
        },
        graphValues2() {
            let ret
            if (this.statList && this.statList.length > 0) {
                let l = this.statList.length
                ret = new Array(l)
                this.statList.forEach((item, itemIdx) => {
                    let d = item.trans_succ_count
                    ret[l - itemIdx - 1] = d
                })

            } else {
                return []
            }

            return ret
        },
        graphValues3() {
            let ret
            if (this.statList && this.statList.length > 0) {
                let l = this.statList.length
                ret = new Array(l)
                this.statList.forEach((item, itemIdx) => {
                    let d = item.drop_call_count - item.visit_history_count
                    ret[l - itemIdx - 1] = d
                })

            } else {
                return []
            }

            return ret
        },
        graphValues4() {
            let ret
            if (this.statList && this.statList.length > 0) {
                let l = this.statList.length
                ret = new Array(l)
                this.statList.forEach((item, itemIdx) => {
                    let d = item.callback_req_count
                    ret[l - itemIdx - 1] = d
                })

            } else {
                return []
            }

            return ret
        }
    },
    methods: {
        searchDateInited(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
            this.initFlags['search_date'] = true
            this.doSearch()
        },
        searchDateChanged(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
        },
        doSearch(no) {
            if (!this.initFlags.search_date) {
                return
            }

            this.$store.dispatch('stats_svc_call/getStatList', {
                searchParam: this.searchParam,
                scb: (stat_list, total_count, va) => {
                },
                fcb: (err, va) => {
                },
                va: this
            })
        },
        initPopper() {
            const chartRef = document.querySelector(".daily-chart")
            const ref = chartRef.querySelector(".active-line");
            const tooltip = this.$refs.tooltip;
            this.popper = createPopper(ref, tooltip, {
                placement: 'right',
            });
        },
        onMouseMoveOfGraph(params) {
            if (params && params.data) {
                try {
                    if (!this.popper) {
                        this.initPopper()
                    }
                    this.popper.update()
                } catch (e) {
                    console.log('popper exception : ', e)
                }
                this.focusedIdx = params.index
                this.focusedData = params.data
            } else {
                this.focusedIdx = -1
                this.forcusedData = null
            }
        },
    }
}
</script>
<style lang="scss">
.tooltip-container {
    padding: 10px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
}

.daily-chart {
    width: 100%;

.vtc {
    height: 600px;
    font-size: 12px;

@media (min-width: 699px) {
    height:

620px

;
}

}
.labels {
    stroke: rgba(0, 0, 0, 0.05);
}

.active-line {
    stroke: rgba(0, 0, 0, 0.5);
}

.point {
    stroke-width: 2;
    transition: stroke-width 0.2s;
}

.curve1 {

.stroke {
    stroke: #d3782e;
    stroke-width: 2;
}

.point {
    fill: #bfa895;
    stroke: #bfa895;
}

.fill {
    fill: #fbac91;
    opacity: 0.05;
}

}
.curve2 {

.stroke {
    stroke: #e7aa47;
    stroke-width: 2;
}

.point {
    fill: #fbe1b6;
    stroke: #fbe1b6;
}

}
.curve3 {

.stroke {
    stroke: #7fdfd4;
    stroke-width: 2;
}

.point {
    fill: #7fdfd4;
    stroke: #7fdfd4;
}

}
.curve4 {

.stroke {
    stroke: #8f7fe9;
    stroke-width: 2;
}

.point {
    fill: #8f7fe9;
    stroke: #8f7fe9;
}

}

}

</style>